import React, { Component, Fragment } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutuser } from "../../../redux/action/auth";
import { loaduser } from "../../../redux/action/auth";
import { PropTypes } from "prop-types";
import NotificationBadge from "../../../components/notification/notification";
import { getNotification } from "../../../redux/action/notification";
import sociCraftLogo from "../../project-wide-images/socicraft logo.png";
import SignIn from "../../content/modal/login/signin";

const noAction = (e) => e.preventDefault();

class Header extends Component {
  state = {
    anchorEl: null,
    signInModalOpen: false,
  };

  togglePopper = (event) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };
  toggleSignInModal = () => {
    this.setState({ signInModalOpen: !this.state.signInModalOpen });
  };
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.getNotification();
    }
  }

  render() {
    const logOut = (e) => {
      e.preventDefault();
      if (this.props.socket) {
        this.props.socket.emit("LOG_OUT");
      }

      this.props.logoutuser();
      return <Redirect to="/#" />;
    };
    const supportPage = () => {
      const url = 'https://support.socicraft.com/portal/en/kb'
      window.open(url, "_self");
    };
    const guest = () => {
      return (
        <ul className="d-flex list-unstyled align-items-center">
          <li className="justify-content-end">
            <span
              className="access-link btn btn-xs btn-gradient btn-gradient-two"
              onClick={() => {
                if (this.props.toggleApply) {
                  this.props.toggleApply();
                }
                if (!this.props.isAuthenticated) {
                  this.toggleSignInModal();
                }
              }}
            >
              <span className="la la-plus"></span> Add Listing
            </span>
          </li>
          <li>
            {/* THIS IS WHERE A MODAL LINK IS */}
            <span
              role="button"
              className="access-link pe-none pb-2"
              style={{ cursor: "pointer" }}
              onClick={() => this.toggleSignInModal()}
            >
              Sign In
            </span>
          </li>
          {/* <li>
            <span>
              <a
                href="/register"
                className="access-link btn btn-xs btn-gradient btn-gradient-one"
              >
                Sign Up
              </a>
            </span>
          </li> */}
        </ul>
      );
    };
    const user = () => {
      return (
        <ul className="d-flex list-unstyled align-items-center">
          <li>
            <NavLink
              to="/add-listing"
              className="btn btn-xs btn-gradient   btn-gradient-two"
            >
              <span className="la la-plus"></span> Add Listing{" "}
            </NavLink>
          </li>
          <li>
            <NotificationBadge />
          </li>

          {this.props.loading && (
            <li style={{ color: "black !important" }}>
              <span style={{ color: "black", fontStyle: "bold" }}>
                {" "}
                Hello, {this.props.user.userName}
              </span>
            </li>
          )}
          <li>
            <div className="author-info">
              {/* <NavLink to="/#" className="author-avatar"> */}
              {this.props.loading && (
                <img
                  onClick={this.togglePopper}
                  width="50px"
                  height="50px"
                  src={this.props.user.userAvatar && this.props.user.userAvatar}
                  alt=""
                  className="rounded-circle"
                />
              )}
              {/* </NavLink> */}
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/social/discovery">Discovery Reflections</NavLink>
                </li>
                <li>
                  <NavLink to="/craft-profile">My Profile</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard-listings">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/">Find Listings</NavLink>
                </li>
                <li>
                  <NavLink to="/chat">Messages</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/view-network-list/${
                      this.props.user && this.props.user.id
                    }`}
                  >
                    Connections
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink to="" onClick={(e) => supportPage(e)}>Support</NavLink>
                </li>
                <li>
                  <NavLink to="/#" onClick={(e) => logOut(e)}>
                    Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      );
    };
    const guestCanvas = () => {
      return (
        <>
          <ul className="d-flex flex-column justify-content-center  list-unstyled align-items-center">
            <li className="justify-content-end pb-3 pt-5">
              <span
                className="access-link btn btn-xs btn-gradient btn-gradient-two"
                onClick={() => {
                  if (this.props.toggleApply) {
                    this.props.toggleApply();
                  }
                  if (!this.props.isAuthenticated) {
                    this.toggleSignInModal();
                  }
                }}
              >
                <span className="la la-plus"></span> Add Listing
              </span>
            </li>
            <li className="pb-3">
              {/* THIS IS WHERE A MODAL LINK IS */}
              <span
                role="button"
                className="access-link pe-none pb-2"
                style={{ cursor: "pointer" }}
                onClick={() => this.toggleSignInModal()}
              >
                Sign In
              </span>
            </li>
            {/* <li className="pb-3">
              <span>
                <a
                  href="/register"
                  className="access-link btn btn-xs btn-gradient btn-gradient-one"
                >
                  Sign Up
                </a>
              </span>
            </li> */}
          </ul>
        </>
      );
    };
    const userCanvas = () => {
      return (
        <>
          <div className="author-avatar">
            {this.props.loading && (
              <>
                {" "}
                {this.props.loading && (
                  <img
                    src={
                      this.props.user.userAvatar && this.props.user.userAvatar
                    }
                    alt={this.props.user.userName && this.props.user.userName}
                    width="50px"
                    height="50px"
                    className="rounded-circle"
                  />
                )}
                <div>
                  <span> {this.props.user.userName}</span>
                </div>
              </>
            )}
          </div>
          {/* <ul className="list-unstyled">
            <li>
              <a
                href="/add-listing"
                className="access-link btn btn-xs btn-gradient btn-gradient-two h-40"
              >
                <span className="la la-plus"></span> Add Listing
              </a>
            </li>
            <li>
              <a href="/social/discovery">Discovery Reflections</a>
            </li>
            <li>
              <a href="/craft-profile">My Profile</a>
            </li>
            <li>
              <a href="/chat">My Message</a>
            </li>
            <li>
              <a href="/dashboard-listings">Dashboard</a>
            </li>
            <li>
              <a
                href={`/view-network-list/${
                  this.props.user && this.props.user.id
                }`}
              >
                My Network
              </a>
            </li>
            <li>
              <NavLink to="/about">Support</NavLink>
            </li>

            <li>
              <NavLink to="/#" onClick={(e) => logOut(e)}>
                Logout
              </NavLink>
            </li>
          </ul> */}
           <ul className="list-unstyled">
                <li>
                  <NavLink to="/social/discovery">Discovery Reflections</NavLink>
                </li>
                <li>
                  <NavLink to="/craft-profile">My Profile</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard-listings">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/">Find Listings</NavLink>
                </li>
                <li>
                  <NavLink to="/chat">Messages</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/view-network-list/${
                      this.props.user && this.props.user.id
                    }`}
                  >
                    Connections
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink to="" onClick={(e) => supportPage(e)}>Support</NavLink>
                </li>
                <li>
                  <NavLink to="/#" onClick={(e) => logOut(e)}>
                    Logout
                  </NavLink>
                </li>
              </ul>
          {/* <div className="search_area">
            <form action="/">
              <div className="input-group input-group-light">
                <input
                  type="text"
                  className="form-control search_field"
                  placeholder="Search here..."
                  autoComplete="off"
                />
              </div>
              <button type="submit" className="btn btn-sm btn-secondary">
                Search
              </button>
            </form>
          </div> */}
        </>
      );
    };

    return (
      <Fragment>
        <div className={"menu-area menu1 " + this.props.class}>
          <SignIn
            toggleSignIn={this.toggleSignInModal}
            modalOpen={this.state.signInModalOpen}
          />

          <div className="top-menu-area">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu-fullwidth">
                    <div className="logo-wrapper order-lg-0 order-sm-1">
                      <div className="logo logo-top">
                        <NavLink to="/social/discovery">
                          <div style={{ width: "100px", height: "50px" }}>
                            <img
                              alt=""
                              style={{ width: "80%" }}
                              src={sociCraftLogo}
                            />
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    {/*<!-- ends: .logo-wrapper -->*/}

                    <div className="menu-right order-lg-2 order-sm-2">
                      {/*<!-- ends: .search-wrapper -->*/}
                      {/*<!-- start .author-area -->*/}
                      <div className="author-area">
                        <div className="author__access_area">
                          {this.props.isAuthenticated ? user() : guest()}
                        </div>
                      </div>

                      {/*<!-- end .author-area -->*/}
                      <div className={"offcanvas-menu d-none"}>
                        <a
                          href="#/"
                          className="offcanvas-menu__user"
                          style={{
                            background: this.props.isAuthenticated && "none",
                          }}
                        >
                          {this.props.isAuthenticated ? (
                            <>
                              {" "}
                              <img
                                src={
                                  this.props.user.userAvatar &&
                                  this.props.user.userAvatar
                                }
                                alt={
                                  this.props.user.userName &&
                                  this.props.user.userName
                                }
                                width="50px"
                                height="40px"
                                className="rounded-circle"
                              />
                            </>
                          ) : (
                            <i className="la la-user"></i>
                          )}
                        </a>

                        <div className="offcanvas-menu__contents">
                          <a href="#/" className="offcanvas-menu__close">
                            <i className="la la-times-circle"></i>
                          </a>
                          {this.props.isAuthenticated
                            ? userCanvas()
                            : guestCanvas()}

                          {/*<!-- ends: .search_area -->*/}
                        </div>
                        {/*<!-- ends: .author-info -->*/}
                      </div>
                      {/*<!-- ends: .offcanvas-menu -->*/}
                      {/*<!-- ends: .offcanvas-menu -->*/}
                    </div>
                    {/*<!-- ends: .menu-right -->*/}
                  </div>
                </div>
              </div>
              {/* <!-- end /.row --> */}
            </div>
            {/* <!-- end /.container --> */}
          </div>
          {/* <!-- end  --> */}
        </div>
        {/* <CallNotification /> */}
      </Fragment>
    );
  }
}
Header.propTypes = {
  loaduser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
  logoutuser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getNotification: PropTypes.func.isRequired,
  socket: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
  socket: state.socket.socket,
});
export default connect(mapStateToProps, {
  loaduser,
  logoutuser,
  getNotification,
})(Header);
