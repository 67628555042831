import React, { useState, useEffect } from "react";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

export default function ApplicantCardDocument({ document }) {
  const [recipient, setRecipient] = useState(null);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (document) {
      const getRecipient = (document) => {
        if (document.recipients.contractor.userId.id.toString() === user.id) {
          setRecipient({
            ...document.recipients.client.userId,
            recipientType: "Client",
          });
        } else if (
          document.recipients.client.userId.id.toString() === user.id
        ) {
          setRecipient({
            ...document.recipients.contractor.userId,
            recipientType: "Contractor",
          });
        }
      };
      getRecipient(document);
    }
  }, [document, user]);

  return (
    recipient && (
      <div>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem
            style={{
              borderTop: "1px solid #e9e7e3",
              borderBottom: "1px solid #e9e7e3",
            }}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <Avatar alt="user Avatar" src={recipient.userAvatar} />
            </ListItemAvatar>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b>{recipient.recipientType} </b> :{" "}
                    <b>
                      {recipient.firstName} {recipient.lastName}
                    </b>{" "}
                  </Typography>
                  <br />
                  <b>{"Created"} </b> :{" "}
                  <b> {moment(document.date).format("DD MMM YYYY")} </b>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </div>
    )
  );
}
